import React, { useRef } from "react";

import { useWindowSize } from "../../hooks";
import { smartDate } from "../../util/date";
import { Animation } from "../shared";
import ConfirmButton from "./ConfirmButton/ConfirmButton";
import Description from "./Description/Description";
import DetailItem from "./DetailItem/DetailItem";
import Fundraising from "./Fundraising/Fundraising";
import PrintButton from "./PrintButton/PrintButton";
import Purpose from "./Purpose/Purpose";
import ShareButton from "./ShareButton/ShareButton";
import styles from "./task.module.css";
import Title from "./Title/Title";

const Task = ({ info }) => {
  const contentRef = useRef();
  const windowSize = useWindowSize();

  const onSwishClick = () =>
    contentRef?.current?.scrollTo({ behavior: "smooth", top: 1000 });

  if (!info) {
    return null;
  }

  return (
    <div className={styles.outerContainer}>
      <Animation key={info?.id} visible={!!info}>
        <div
          className={styles.innerContainer}
          ref={contentRef}
          style={{ maxHeight: windowSize?.height / 2 - 32 }}
        >
          <div className={styles.outerContent}>
            <div className={styles.innerContent}>
              <Title onSwishClick={onSwishClick} value={info.name} />
              <Description value={info.description} />
              <Purpose value={info.purpose} />
              <div className={styles.actionButtons}>
                <ShareButton />
                <PrintButton />
                <ConfirmButton cleaned={!!info.cleaned} />
              </div>
              <div className={styles.details}>
                <DetailItem
                  label="Skapat"
                  value={smartDate(info.created) || "-"}
                />
                <DetailItem label="Skapat av" value={info.contact} />
                <DetailItem label="Städas av" value={info.cleaner || "-"} />
                <DetailItem
                  label="Städas senast"
                  value={smartDate(info.deadline) || "Inget datum"}
                />
                <DetailItem
                  label="Städat"
                  value={smartDate(info.cleaned) || "Ännu inte städat"}
                />
              </div>
            </div>
            <Fundraising
              currentAmount={info.currentAmount}
              taskId={info.id}
              totalAmount={info.totalAmount}
            />
          </div>
          <div className={styles.bottomFade} />
        </div>
      </Animation>
    </div>
  );
};

export default Task;
