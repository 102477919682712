import React from "react";

const ExtraMoney = () => {
  return (
    <div>
      <b>Vill du och din klass/lag tjäna extrapengar?</b>
      <p>
        Planerar ni en klassresa, kanske ett träningsläger eller ny utrustning
        till en träningshall? Oavsett varför så finns det mängder av skäl för en
        exempelvis en klass eller ett idrottslag att tillsammans vilja tjäna
        extrapengar och det vill vi så klart hjälpa er med!
      </p>
      <p>
        Till skillnad från att knacka dörr för att exempelvis sälja tubsockor,
        godis eller kakor, och då vanligtvis få behålla cirka 40% av pengarna ni
        slitit för, kan ni med CleanApp istället tjäna ihop extrapengarna genom
        att umgås och röra på er tillsammans utomhus, göra en viktig
        samhällsinsats för lokalsamhället genom att städa naturen och därtill få
        behålla 80% av pengarna - svårslaget, eller hur?!
      </p>
      <img
        alt="barn"
        src={require("../../../images/illustrations/child-spade.png")}
        style={{ float: "right", width: 300 }}
      />
      <p>
        Ni i klassen/laget får inte bara betalt för er viktiga insats. Ni får
        även ett diplom att anslå tydligt i skolan eller i klubbloken, ni listas
        på CleanApps webbsida och ni får välförtjänt uppmärksamhet i våra
        sociala medier som en av alla klasser/lag som gjort skillnad på riktigt
        - bra jobbat och ett stort tack för hjälpen!
      </p>
      <p>
        Därtill slipper föräldrar, lagledare m.fl. all tidsödande
        administration, logistik, lagerhållning m.m. som det ofta innebär när
        hela klasser/lag ska sälja prylar, kakor eller godis för att tjäna
        extrapengar till klass- och lagkassan.
      </p>
      <p>
        Så hitta och välj nu ett eller flera områden att GULMARKERA och leta
        sedan efter sponsor och bidrag till ert städuppdrag!
      </p>
      <p>
        Är det kanske någon förälders företag som vill sponsra er eller något
        annat lokalt företag som vill stötta er och ert viktiga städuppdrag?
        Alla föräldrar, vänner, släktingar och bekanta kan självklart också
        bidra. När ni nått ert uppsatta målbelopp, så är det bara att sätta
        igång med städuppdraget och direkt därefter får ni betalt för er viktiga
        och uppskattade insats och därtill välförtjänt uppmärksamhet!
      </p>
    </div>
  );
};

export default ExtraMoney;
