import classNames from "classnames";
import React from "react";
import { NavLink } from "react-router-dom";

import styles from "./marker.module.css";

const Overlay = ({ color, id, pulse, small }) => {
  const marker = (
    <div
      className={classNames({
        [styles.marker]: true,
        [styles.small]: small,
      })}
      style={{ backgroundColor: color }}
    />
  );
  return (
    <div
      className={classNames({
        [styles.pulsating]: pulse,
        [styles.small]: small,
      })}
    >
      {id ? <NavLink to={`/uppdrag/${id}`}>{marker}</NavLink> : marker}
    </div>
  );
};

export default Overlay;
