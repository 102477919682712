import PropTypes from "prop-types";
import React from "react";

import styles from "./partners.module.css";

const Partners = ({ include, size }) => {
  const partners = [
    {
      code: "almi",
      logo: require("../../../images/partners/almi.jpeg"),
    },
    { code: "seb", logo: require("../../../images/partners/seb.jpeg") },
    {
      code: "swish",
      logo: require("../../../images/partners/swish-horizontal.png"),
    },
    {
      code: "facebook",
      link: "//www.facebook.com/cleanapp.se",
      logo: require("../../../images/partners/facebook.png"),
    },
  ];

  const filteredPartners = partners?.filter((item) =>
    include?.includes(item.code)
  );

  return (
    <div className={styles.container}>
      {filteredPartners?.map((item, index) => {
        const logo = (
          <img
            alt={item.code}
            key={index}
            src={item.logo}
            style={{ height: size, margin: size / 2 }}
          />
        );
        return item.link ? (
          <a href={item.link} key={index} rel="noreferrer" target="_blank">
            {logo}
          </a>
        ) : (
          logo
        );
      })}
    </div>
  );
};

Partners.propTypes = {
  include: PropTypes.arrayOf(
    PropTypes.oneOf(["almi", "facebook", "seb", "swish"])
  ),
  size: PropTypes.number,
};

Partners.defaultProps = {
  include: ["almi", "facebook", "seb", "swish"],
  size: 32,
};

export default Partners;
