import GoogleMapReact from "google-map-react";
import React, { useEffect, useState } from "react";

import { googleMapsKey } from "../../constants/config";
import { showError } from "../../util/message";
import Marker from ".//Marker/Marker";
import styles from "./map.module.css";

const Map = ({ enableDrawing, markers, onNewMarker, selectedMarker }) => {
  const [map, setMap] = useState(null);
  const [googleMaps, setGoogleMaps] = useState(null);
  const [listener, setListener] = useState(null);
  const [myLocation, setMyLocation] = useState(null);

  useEffect(() => {
    if (map && googleMaps) {
      if (enableDrawing && !listener) {
        const listener = map.addListener("click", (event) => {
          const location = {
            lat: event.latLng.lat(),
            lng: event.latLng.lng(),
          };
          onNewMarker(location);
        });
        setListener(listener);
      } else if (!enableDrawing && listener) {
        listener.remove();
        setListener(null);
      }
    }
  }, [enableDrawing, listener, markers, map, googleMaps, onNewMarker]);

  const handleGoogleApiLoaded = ({ map, maps }) => {
    setMap(map);
    setGoogleMaps(maps);
  };

  useEffect(() => {
    if (googleMaps && map) {
      if (enableDrawing || markers.length === 0) {
        // Do not navigate if user is drawing on map or if there are no markes.
        return null;
      }

      if (selectedMarker) {
        // Go to selected marker.
        const center = { lat: selectedMarker.lat, lng: selectedMarker.lng };
        map.setCenter(center);
        map.setZoom(13);
      } else {
        // Zoom out to show all markers.
        const bounds = new googleMaps.LatLngBounds();
        markers.forEach((item) => {
          const coordinate = new googleMaps.LatLng(item.lat, item.lng);
          bounds.extend(coordinate);
        });
        map.fitBounds(bounds);
      }
    }
  }, [enableDrawing, googleMaps, map, markers, selectedMarker]);

  useEffect(() => {
    if (googleMaps && map) {
      // 1. Create pin icon.
      const pinImage = document.createElement("img");
      pinImage.src = require("../../images/icons/pin.png");
      pinImage.width = 16;
      pinImage.height = 16;
      pinImage.classList.add(styles.pinImage);
      // 2. Create "find location" label.
      const locationLabel = document.createElement("span");
      locationLabel.innerText = "Hitta min position";
      // 3. Put together and create button.
      const locationButton = document.createElement("button");
      locationButton.appendChild(pinImage);
      locationButton.appendChild(locationLabel);
      locationButton.classList.add(styles.myLocation);
      // 4. Add to Google Map.
      map.controls[googleMaps.ControlPosition.TOP_RIGHT].push(locationButton);
      // 5. Find location when clicking on it.
      locationButton.addEventListener("click", () => {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            (position) => setMyLocation(position.coords),
            () => {
              showError("Kunde inte hitta din position.");
            }
          );
        } else {
          showError("Din webbläsare stödjer inte geolocation.");
        }
      });
    }
  }, [map, googleMaps]);

  useEffect(() => {
    if (myLocation) {
      const center = {
        lat: myLocation.latitude,
        lng: myLocation.longitude,
      };
      map.setCenter(center);
      map.setZoom(15);
    }
  }, [map, myLocation]);

  return (
    <GoogleMapReact
      bootstrapURLKeys={{ key: googleMapsKey }}
      defaultCenter={{ lat: 57.7, lng: 11.9 }}
      defaultZoom={10}
      onGoogleApiLoaded={handleGoogleApiLoaded}
      options={{ fullscreenControl: false }}
      yesIWantToUseGoogleMapApiInternals
    >
      {markers.map((item, index) => (
        <Marker
          color={item.color}
          id={item.id}
          key={index}
          lat={item.lat}
          lng={item.lng}
          pulse={item.pulse}
        />
      ))}
      {myLocation && (
        <Marker
          color="#333"
          lat={myLocation.latitude}
          lng={myLocation.longitude}
          pulse
          small
        />
      )}
    </GoogleMapReact>
  );
};

export default Map;
