import React from "react";
import { NavLink } from "react-router-dom";

import styles from "./sectionLinks.module.css";

const SectionLinks = ({ items }) => {
  return (
    <div className={styles.container}>
      <NavLink
        activeClassName={styles.activeItem}
        className={styles.item}
        isActive={(match, location) =>
          ["/om", "/om/"].includes(location?.pathname)
        }
        to="/om"
      >
        Hem
      </NavLink>
      {items.map((item, index) => (
        <NavLink
          activeClassName={styles.activeItem}
          className={styles.item}
          key={item.url}
          to={`/om/${item.url}`}
        >
          {index + 1}. {item.title}
        </NavLink>
      ))}
    </div>
  );
};

export default SectionLinks;
