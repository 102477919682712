import "react-notifications/lib/notifications.css";

import { NotificationManager } from "react-notifications";

export const showMessage = (message) => {
  NotificationManager.info(message);
};

export const showSuccess = (message) => {
  NotificationManager.success(message);
};

export const showError = (message) => {
  message = message || "Vänligen försök igen.";
  NotificationManager.error(message, "Något gick fel");
};
