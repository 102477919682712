import React, { useEffect } from "react";

import { Animation } from "../shared";
import styles from "./about.module.css";
import Background from "./Background/Background";
import CleanApp from "./CleanApp/CleanApp";
import ExtraMoney from "./ExtraMoney/ExtraMoney";
import FAQ from "./FAQ/FAQ";
import InfoLeader from "./InfoLeader/InfoLeader";
import Littering from "./Littering/Littering";
import MoneyToYouth from "./MoneyToYouth/MoneyToYouth";
import Partners from "./Partners/Partners";
import SectionLinks from "./SectionLinks/SectionLinks";
import Sponsor from "./Sponsor/Sponsor";
import TableOfContents from "./TableOfContents/TableOfContents";

const About = ({ location, match }) => {
  useEffect(() => {
    // Scroll to top every time link is clicked since user might have scrolled
    // down before clicking on link.
    document
      .getElementById("root-content")
      .scrollTo({ behavior: "smooth", top: 0 });
  }, [location]);

  const sections = [
    {
      component: Littering,
      description:
        "Nedskräpning i vår natur är ett av vår tids största samhällsproblem, både globalt och lokalt.",
      title: "Nedskräpning i siffror",
      url: "nedskrapning",
    },
    {
      component: CleanApp,
      description:
        "Även om nedskräpningen till stor del är ett globalt problem så finns många av lösningarna på lokal nivå.",
      title: "Detta är CleanApp",
      url: "clean-app/simon-eisner",
    },
    {
      component: ExtraMoney,
      description:
        "Planerar ni en klassresa, kanske ett träningsläger eller ny utrustning till en träningshall?",
      title: "Extrapengar till laget/klassen",
      url: "extra-pengar",
    },
    {
      component: Sponsor,
      description:
        "Återigen dags för barnens klass eller lag att samla in pengar till klassresan, terminsfesten eller den nya träningsutrustningen? ",
      title: "Sponsra eller stötta ett städuppdrag?",
      url: "sponsring",
    },
    {
      component: InfoLeader,
      description:
        "Planerar ni en klassresa, kanske ett träningsläger eller ny utrustning till en träningshall?",
      title: "För lärare, lagledare m.fl.",
      url: "info-for-larare-lagledare",
    },
    {
      component: MoneyToYouth,
      description:
        "Oavsett om ett städuppdrag genomförs för att tjäna extrapengar till klassen/laget, för att göra en viktig samhällsinsats, för att hitta på något annorlunda och kul tillsammans eller kanske för alla dessa olika skäl så ska självklart ungdomarna som utför varje städuppdrag få både bra betalt och behålla så stor andel som möjligt av deras insamlade pengar.",
      title: "Pengarna går till ungdomarna",
      url: "pengar-till-ungdomarna",
    },
    {
      component: Background,
      description:
        "Grunden för socialt entreprenörskap inom den cirkulära ekonomin är att finna affärsmässigt hållbara lösningar på olika former av samhällsproblem och då med en långsiktig ekonomisk, ekologisk och social hållbarhet genom hela utvecklings- och affärsprocessen.",
      title: "Om oss, bakgrund och kontakt",
      url: "bakgrund/simon-eisner",
    },
    {
      component: Partners,
      description: "Vi samarbetar med Almi, SEB och Swish.",
      title: "Samarbetspartners",
      url: "partners",
    },
    {
      component: FAQ,
      description: "Läs om de vanligaste frågorna som vi får.",
      title: "Vanliga frågor och svar",
      url: "faq",
    },
  ];
  const { section } = match?.params || {};
  const selectedSection = sections.find(
    (a) => a.url?.replace("/simon-eisner", "") === section
  );
  const Component = selectedSection?.component;
  return (
    <div>
      <div className={styles.section}>
        {selectedSection ? (
          <>
            <SectionLinks items={sections} />
            <Animation key={selectedSection.url}>
              <Component />
            </Animation>
          </>
        ) : (
          <TableOfContents items={sections} />
        )}
        <b>Kontakt</b>
        <p>Simon Eisner</p>
        <p>
          Telefon: 0733 76 71 24
          <br />
          Mail:{" "}
          <a href="mailto:info@cleanapp.se" rel="noreferrer" target="_blank">
            info@cleanapp.se
          </a>
          <br />
          Facebook:{" "}
          <a
            href="//www.facebook.com/cleanapp.se"
            rel="noreferrer"
            target="_blank"
          >
            www.facebook.com/cleanapp.se
          </a>
        </p>
        <p>
          Hör gärna av dig om du vill delta, hjälp till eller bara höra mer om
          hur vi tillsammans kan göra nytta!
        </p>
      </div>
    </div>
  );
};

export default About;
