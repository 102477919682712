import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { useLocation } from "react-router-dom";

import { Partners } from "../../shared";
import Links from "../Links/Links";
import Logo from "../Logo/Logo";
import styles from "./mobileMenu.module.css";

const MobileMenu = ({ items, slogan }) => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    // Close menu whenever location is changed, i.e. link in menu was clicked.
    setIsOpen(false);
  }, [location]);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <>
      <div className={styles.container}>
        <Logo width={80} />
        <div className={styles.slogan}>{slogan}</div>
        <img
          alt="meny"
          className={styles.openIcon}
          onClick={openModal}
          src={require("../../../images/icons/menu.png")}
        />
      </div>
      <Modal
        className={styles.modal}
        isOpen={isOpen}
        onRequestClose={closeModal}
        overlayClassName={styles.overlay}
      >
        <div className={styles.header}>
          <img
            alt="stäng"
            className={styles.closeIcon}
            onClick={closeModal}
            src={require("../../../images/icons/close.png")}
          />
        </div>
        <Links direction="column" items={items} />
        <Partners size={32} />
      </Modal>
    </>
  );
};

export default MobileMenu;
