import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";

import styles from "./textInput.module.css";

const TextInput = ({
  className,
  inputClassName,
  label,
  multiline,
  onChange,
  placeholder,
  type,
  value,
}) => {
  const onChangeText = (event) => onChange(event?.target?.value);
  const component = React.createElement(multiline ? "textarea" : "input", {
    className: classNames({
      [styles.input]: true,
      [styles.textarea]: multiline,
      [inputClassName]: true,
    }),
    onChange: onChangeText,
    placeholder: placeholder,
    type: type,
    value: value,
  });
  return (
    <div className={className}>
      {!!label && <div className={styles.label}>{label}</div>}
      {component}
    </div>
  );
};

TextInput.propTypes = {
  className: PropTypes.string,
  inputClassName: PropTypes.string,
  label: PropTypes.any, // Could be a component as well.
  multiline: PropTypes.bool,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  type: PropTypes.oneOf(["text", "number"]),
  value: PropTypes.string,
};

TextInput.defaultProps = {
  className: null,
  inputClassName: null,
  label: "",
  multiline: false,
  onChange: null,
  placeholder: "",
  type: "text",
  value: "",
};

export default TextInput;
