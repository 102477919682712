import React from "react";

import { formatAmount } from "../../../util/string";
import styles from "./fundraising.module.css";
import FundraisingIndicator from "./FundraisingIndicator/FundraisingIndicator";
import SwishButton from "./SwishButton/SwishButton";

const Fundraising = ({ currentAmount, taskId, totalAmount }) => {
  return (
    <div className={styles.container}>
      <div className={styles.amount}>{formatAmount(currentAmount)}</div>
      <div className={styles.label}>
        hittills insamlat av {formatAmount(totalAmount)}
      </div>
      <FundraisingIndicator
        percentage={parseInt((100 * currentAmount) / totalAmount)}
      />
      <SwishButton taskId={taskId} />
      <div className={styles.invoice}>
        Vill du stötta detta städuppdrag mot faktura? Kontakta oss på{" "}
        <a href="mailto:info@cleanapp.se">info@cleanaapp.se</a>.
      </div>
    </div>
  );
};

export default Fundraising;
