import React, { useState } from "react";

import styles from "./swishButton.module.css";
import SwishOverlay from "./SwishOverlay/SwishOverlay";

const SwishButton = ({ taskId }) => {
  const [open, setOpen] = useState(false);
  const onOpen = () => setOpen(true);
  const onClose = () => setOpen(false);
  return (
    <>
      <div className={styles.container} onClick={onOpen}>
        <img
          alt="swish"
          className={styles.logo}
          src={require("../../../../images/partners/swish-vertical.png")}
        />
        <div>Stötta detta uppdrag med Swish!</div>
      </div>
      <SwishOverlay onClose={onClose} taskId={open ? taskId : null} />
    </>
  );
};

export default SwishButton;
