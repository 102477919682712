import React from "react";

import styles from "./shareButton.module.css";

export default function ShareButton() {
  const shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${window?.location?.href}`;

  return (
    <a
      className={styles.container}
      href={shareUrl}
      rel="noreferrer"
      target="_blank"
    >
      Dela på Facebook
    </a>
  );
}
