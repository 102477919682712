import classNames from "classnames";
import React from "react";
import { NavLink } from "react-router-dom";

import styles from "./links.module.css";

const Links = ({ direction, items }) => {
  return (
    <div
      className={classNames({
        [styles.container]: true,
        [styles.column]: direction === "column",
        [styles.row]: direction === "row",
      })}
    >
      {items.map((item, index) => (
        <NavLink
          activeClassName={styles.activeLink}
          className={styles.link}
          isActive={item.isActive}
          key={index}
          to={item.href}
        >
          {item.label}
        </NavLink>
      ))}
    </div>
  );
};

export default Links;
