import React from "react";

const Littering = () => {
  return (
    <div>
      <b>Nedskräpning - ett globalt problem som drabbar oss alla lokalt!</b>
      <p>
        Nedskräpning i vår natur är ett av vår tids största samhällsproblem,
        både globalt och lokalt. Skräpet skapar en känsla av otrygghet i våra
        bostadsområden, förfular våra parker och förstör våra städer. Skräpet
        skadar därtill både vilda djur och våra tama sällskapsdjur, förstör
        haven och kostar oerhört mycket pengar!
      </p>
      <p>
        För att förstå nedskräpningens enorma omfattning har vi nedan samlat en
        hel del skrämmande siffror, dessa från bl.a. Naturvårdsverket,
        Världsnaturfonden och flertalet olika expertstudier. Trots att siffrorna
        är dystra så hoppas vi att dessa fakta ska ge en stark känsla av att
        vilja bidra till en lösning och förbättring, inte en känsla av
        uppgivenhet!
      </p>
      <img
        alt="nedskärpning"
        src={require("../../../images/illustrations/garbage.png")}
        style={{ float: "right", width: 300 }}
      />
      <ul>
        <li>
          Fler än nio av tio, eller 93%, av svenskarna anser att nedskräpning
          generellt är ett problem i samhället.
        </li>
        <li>
          Sveriges 290 kommuner lägger redan mer än 2 miljarder/år av våra
          skattemedel varje år på att plocka upp skräp.
        </li>
        <li>
          Över hälften, cirka 55 procent, av svenskarna anser ändå att
          nedskräpningen ökar i samhället.
        </li>
        <li>
          Under en enda vecka i juni 2020 slängde svenskar cirka 35 miljoner
          skräpföremål på våra trottoarer, gågator, gång- och cykelvägar. Den
          sammanlagda vikten av skräpet var cirka 60 ton.
        </li>
        <li>
          Fem biljoner plastbitar, med en sammanlagd vikt på 269.000 ton, flyter
          runt i världshaven.
        </li>
        <li>
          Plastskräpet är ett av de största hoten mot den biologiska mångfalden
          i haven. Mer än en miljon fåglar och hundratusen däggdjur dör varje år
          efter att ha fastnat i eller ätit plast i våra hav.
        </li>
        <li>
          Oavsett om skräpet hamnar i naturen eller i våra hav så bryts skräpet
          ner väldigt långsamt, så helst ska det inte slängas där från början
          eller i alla fall tas upp så snart som möjligt.
        </li>
        <li>
          Nedbrytningstiden skiljer sig åt, beroende på vad det är för skräp och
          vart det slängs, här är ett par exempel på nedbrytningstider i
          naturen:
          <ul>
            <li>Äppelskrutt: 1 månad</li>
            <li>Papperspåse: 1 månad</li>
            <li>Plastpåse: 10 - 20 år</li>
            <li>Tuggummi 20 - 25 år</li>
            <li>Aluminiumburk: 200 - 500 år</li>
            <li>Glas: 1 miljon år</li>
          </ul>
        </li>
      </ul>
    </div>
  );
};

export default Littering;
