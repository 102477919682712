import styles from "./details.module.css";

export default function Details({ cleaner, contact, deadline }) {
  return (
    <div className={styles.container}>
      <div className={styles.item}>
        <div className={styles.header}>Skapat av</div>
        <div>{contact}</div>
      </div>
      <div className={styles.item}>
        <div className={styles.header}>Städas av</div>
        <div>{cleaner}</div>
      </div>
      <div className={styles.item}>
        <div className={styles.header}>Städas senast</div>
        <div>{deadline}</div>
      </div>
    </div>
  );
}
