import React from "react";

const MoneyToYouth = () => {
  return (
    <div>
      <b>CleanApp ger mer!</b>
      <img
        alt="löv"
        src={require("../../../images/illustrations/leaves.png")}
        style={{ float: "right", width: 200 }}
      />
      <p>
        Oavsett om ett städuppdrag genomförs för att tjäna extrapengar till
        klassen/laget, för att göra en viktig samhällsinsats, för att hitta på
        något annorlunda och kul tillsammans eller kanske för alla dessa olika
        skäl så ska självklart ungdomarna som utför varje städuppdrag få både
        bra betalt och behålla så stor andel som möjligt av deras insamlade
        pengar.
      </p>
      <p>
        För att säkerställa att de pengar som företag, organisationer, föräldrar
        m.fl. sponsrar och bidrar med verkligen går till ungdomarna och deras
        städuppdrag så har vi på CleanApp valt att inspireras av den
        fördelningsprincip, den så kallade ”90-kontoprincipen”, som bl.a. Rädda
        Barnen, Röda Korset och Barncancerfonden nyttjar.
      </p>
      <p>
        Fördelningsprincipen är fastslagen av organisationen Svensk
        Insamlingskontroll och innebär att minst 75% av alla enskilt satsade
        bidrag och sponsorbelopp ska gå till ändamålet och utförarna, i
        CleanApp&apos;s fall till ungdomarna som åtagit sig och utfört ett
        städuppdrag.
      </p>
      <p>
        Vi på CleanApp har dock valt att lägga ambitionsnivån ännu högre och har
        därför istället valt att 80% av alla de pengar som sponsorer eller
        bidragsgivare stöttar med ska gå direkt till ungdomarna och till deras
        klass- eller lagkassa, detta är svårslaget!
      </p>
      <b>Ekonomi och betalning</b>
      <img
        alt="barn"
        src={require("../../../images/illustrations/child-money.png")}
        style={{ float: "right", width: 300 }}
      />
      <ul>
        <li>
          Minsta belopp som ni samla in via sponsorer eller frivilliga bidrag
          per städuppdrag är helt valfritt och helt beroende på vad ni samlar in
          pengarna till och hur mycket nu behöver.
        </li>
        <li>
          CleanApp tar enbart ut en deltagar- och administrationsavgift, på 20%
          av insamlade pengar. Det tillkommer inga övriga fasta eller rörliga
          kostnader eller avdrag för CleanApp&apos;s administration, trycksaker,
          kommunikation, betalningstjänster m.m.
        </li>
        <li>
          Då deltagaravgiften är fast på 20% så innebär det att klassen/laget
          alltid får behålla 80% av varje insamlad krona som går direkt till
          ungdomarnas städuppdrag och klass- eller lagkassa!
        </li>
        <li>
          Det är upp till ungdomarna att välja ett eller flera områden som
          enskilt motsvarar det upplevda värdet så att företag, föräldrar m.fl.
          vill sponsra och bidra till utvalda städuppdrag med sammantaget det
          målbelopp ni väljer.
        </li>
        <li>
          Privatpersoner, såsom föräldrar och bekanta, som frivilligt väljer att
          sponsra ett städuppdrag betalar enkelt via Swish det belopp (minst 100
          kr/bidrag) som de vill bidra med till ungdomarnas städuppdrag. När
          klassen/laget lyckats samla ihop det totala målbeloppet som de själva
          satt upp kan de utföra städuppdraget. Efter att städuppdraget är
          utfört och bekräftat får klassen/laget pengarna utbetalda till sig
          från CleanApp.
        </li>
        <li>
          De företag/organisationer som erbjuder sig att sponsra hela eller
          delar av ett angivet städuppdrag får istället en faktura från CleanApp
          på det valda sponsorbeloppet (minst 1.000 kr exkl.
          moms/sponsortillfälle och städuppdrag). Efter att städuppdraget är
          utfört och bekräftat får klassen/laget pengarna utbetalda/överförda
          till sig från CleanApp.
        </li>
        <li>
          Om ungdomarna inte når det uppsatta minimibeloppet förutsätts
          ungdomarna ändå utföra de av dem antagna städuppdraget inom tre
          månader.
        </li>
        <li>
          Utförs inte städuppdraget inom tidsgränsen på tre månader avsätts
          pengarna för framtida CleanApp-projekt kopplade till renhållning och
          minskad nedskräpning av vår natur.
        </li>
      </ul>
    </div>
  );
};

export default MoneyToYouth;
