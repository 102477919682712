import React from "react";

import { green, yellow } from "../../constants/colors";
import styles from "./list.module.css";
import ListItem from "./ListItem/ListItem";
import ToggleButton from "./ToggleButton/ToggleButton";

const List = ({
  onShowCleaned,
  onShowCreated,
  showCleaned,
  showCreated,
  tasks,
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.header}>Vilka uppdrag vill du visa?</div>
        <ToggleButton
          checked={showCleaned}
          color={green}
          label="Avklarade städuppdrag"
          onToggle={onShowCleaned}
        />
        <ToggleButton
          checked={showCreated}
          color={yellow}
          label="Pågående städuppdrag att stötta!"
          onToggle={onShowCreated}
        />
        <div className={styles.header}>Visar {tasks.length} uppdrag:</div>
        {tasks.length === 0 ? (
          <div className={styles.empty}>Finns inga uppdrag att visa.</div>
        ) : (
          tasks.map((item, index) => (
            <ListItem
              cleaner={item.cleaner}
              color={item.color}
              id={item.id}
              key={index}
              name={item.name}
            />
          ))
        )}
      </div>
    </div>
  );
};

export default List;
