import React, { useState } from "react";

import { darkGrey } from "../../constants/colors";
import List from "../List/List";
import Map from "../Map/Map";
import NewTask from "../NewTask/NewTask";
import Task from "../Task/Task";
import styles from "./app.module.css";

const App = ({ history, match, tasks }) => {
  const [newMarker, setNewMarker] = useState(null);
  const [showCreated, setShowCreated] = useState(true);
  const [showCleaned, setShowCleaned] = useState(false);

  const onClose = () => {
    setNewMarker(null);
    history.push("/");
  };

  const onNewSuccess = (taskId) => {
    setNewMarker(null);
    history.push(`/uppdrag/${taskId}`);
    window.location.reload();
  };

  const isVisible = (item) => {
    if (item.cleaned && showCleaned) {
      return true;
    } else if (!item.cleaned && showCreated) {
      return true;
    }
    return false;
  };

  // Tasks shown in the list;
  const visibleTasks = tasks?.filter((item) => isVisible(item));

  // Markers visible on the map (same as above but in other format);
  const visibleMarkers = visibleTasks
    .map((item) => ({
      pulse: parseInt(match?.params?.id) === item.id,
      ...item,
    }))
    .concat({
      color: darkGrey,
      id: "new",
      lat: newMarker?.lat,
      lng: newMarker?.lng,
      pulse: true,
    })
    .filter((item) => !!item?.lat && item?.lng);

  const selectedTask = tasks?.find(
    (item) => parseInt(match?.params?.id) === item.id
  );

  const enableDrawing = match?.path === "/uppdrag/skapa";

  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <List
          onShowCleaned={setShowCleaned}
          onShowCreated={setShowCreated}
          showCleaned={showCleaned}
          showCreated={showCreated}
          tasks={visibleTasks}
        />
        <div className={styles.content}>
          {enableDrawing && (
            <NewTask
              marker={newMarker}
              onClose={onClose}
              onSuccess={onNewSuccess}
            />
          )}
          <Task info={selectedTask} />
          <div className={styles.map}>
            <Map
              enableDrawing={enableDrawing}
              markers={visibleMarkers}
              onNewMarker={setNewMarker}
              selectedMarker={selectedTask}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default App;
