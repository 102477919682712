import { useEffect, useState } from "react";
import ReactQRCode from "react-qr-code";

import { Partners } from "../shared";
import Details from "./Details/Details";
import Links from "./Links/Links";
import styles from "./printTask.module.css";

export default function PrintTask({ match, tasks }) {
  const [task, setTask] = useState(null);

  useEffect(() => {
    if (tasks?.length > 0) {
      const selectedTask = tasks?.find(
        (item) => parseInt(match?.params?.id) === item.id
      );
      setTask(selectedTask);
    }
  }, [match, tasks]);

  useEffect(() => {
    if (task) {
      window.print();
    }
  }, [task]);

  if (!task) {
    return null;
  }

  return (
    <div className={styles.container}>
      <div className={styles.section}>
        <img
          alt="CleanApp"
          className={styles.logo}
          src={require("../../images/logo.png")}
        />
        <div className={styles.slogan}>
          Städa naturen och tjäna extrapengar!
        </div>
      </div>
      <div className={styles.section}>
        <div className={styles.name}>{task?.name}</div>
      </div>
      <div className={styles.section}>
        <div className={styles.header}>Beskrivning av städuppdraget:</div>
        <div>{task?.description}</div>
      </div>
      <div className={styles.section}>
        <div className={styles.header}>
          Vi vill använda insamlade extrapengar till:
        </div>
        <div>{task?.purpose}</div>
      </div>
      <div className={styles.section}>
        <Details
          cleaner={task?.cleaner}
          contact={task?.contact}
          deadline={task?.deadline}
        />
      </div>
      <div className={styles.section}>
        <div className={styles.support}>
          Skanna QR-koden med din MOBILKAMERA för att stötta vårt städuppdrag!
        </div>
        <div className={styles.qrCode}>
          <ReactQRCode
            size={120}
            value={`https://cleanapp.se/uppdrag/${task?.id}`}
          />
        </div>
      </div>
      <div className={styles.section}>
        <Partners include={["almi", "seb", "swish"]} size={32} />
        <Links />
      </div>
    </div>
  );
}
