import React, { useEffect, useRef, useState } from "react";

import styles from "./fundraisingIndicator.module.css";

const FundraisingIndicator = ({ percentage }) => {
  const [color, setColor] = useState("#000");
  const [left, setLeft] = useState(null);
  const [right, setRight] = useState(null);
  const indicatorRef = useRef();
  const labelRef = useRef();
  useEffect(() => {
    if (labelRef?.current?.clientWidth > indicatorRef?.current?.clientWidth) {
      setColor("#000");
      setLeft(indicatorRef?.current?.clientWidth);
    } else {
      setColor("#fff");
      setRight(0);
    }
  }, [percentage]);
  return (
    <div className={styles.container}>
      <div
        className={styles.indicator}
        ref={indicatorRef}
        style={{ width: `${percentage}%` }}
      >
        <div
          className={styles.label}
          ref={labelRef}
          style={{ color, left, right }}
        >
          {percentage}%
        </div>
      </div>
    </div>
  );
};

export default FundraisingIndicator;
