import React, { useState } from "react";
import { useParams } from "react-router-dom";

import { post } from "../../../util/server";
import { Button, TextInput } from "../../shared";
import styles from "./purpose.module.css";

const Purpose = ({ value }) => {
  const params = useParams();
  const [isEditing, setIsEditing] = useState(false);
  const [newValue, setNewValue] = useState(value);

  const canEdit = !!params?.hash;
  const onToggle = () => setIsEditing(!isEditing);
  const onSubmit = async () => {
    post("update_task", {
      hash: params?.hash,
      purpose: newValue,
      taskId: params?.id,
    });
    setIsEditing(false);
  };

  if (!value && !canEdit) {
    return null;
  }

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        Vi vill använda insamlade extrapengar till:
      </div>
      {isEditing ? (
        <TextInput
          inputClassName={styles.textarea}
          multiline
          onChange={setNewValue}
          value={newValue}
        />
      ) : (
        <div>{newValue || value}</div>
      )}
      {canEdit && (
        <div className={styles.toolbar}>
          {isEditing && (
            <Button
              className={styles.submitButton}
              color="green"
              onClick={onSubmit}
              title="Uppdatera"
            />
          )}
          <Button
            onClick={onToggle}
            title={isEditing ? "Avbryt" : "Ändra ändamål?"}
          />
        </div>
      )}
    </div>
  );
};

export default Purpose;
