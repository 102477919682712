import React from "react";

const Sponsor = () => {
  return (
    <div>
      <b>Föräldrar, släkt och vänner</b>
      <p>
        Återigen dags för barnens klass eller lag att samla in pengar till
        klassresan, terminsfesten eller den nya träningsutrustningen?
      </p>
      <img
        alt="löv"
        src={require("../../../images/illustrations/leaves.png")}
        style={{ float: "right", width: 300 }}
      />
      <p>
        Vad ska de sälja denna gång; lotter, tubsockor, kakor, godis eller
        salamikorvar? Ja, vi har alla föräldrar varit där och det är alltid med
        blandade känslor vi står inför dessa köp- och säljsituationer. Finns det
        verkligen inget vettigare ungdomarna kan göra än att sälja prylar och
        sötsaker för att tillsammans tjäna extrapengar?
      </p>
      <p>
        Med CleanApp kan ni föräldrar, släktingar och bekanta stötta era
        ungdomar med ett helt valfritt belopp (minst 100 kr/bidrag) och istället
        på det sättet bidra till deras viktiga städuppdrag. Många bäckar små och
        när ungdomarnas målbelopp är uppnått kan klassen/laget utföra det
        viktiga städuppdraget och få betalt för deras viktiga insats!
      </p>
      <p>
        Tack vara ditt viktiga stöd till ungdomarna och deras städuppdrag via
        CleanApp bidrar du på många sätt:
      </p>
      <ul>
        <li>Ungdomarna får möjlighet att tjäna extrapengar tillsammans!</li>
        <li>Ungdomarna städar i naturen istället för att kränga saker!</li>
        <li>Ungdomarna lära sig nyttan av att inte skräpa ner i vår natur!</li>
        <li>
          Ungdomarna får behålla 80% av pengarna till skillnad från ordinarie
          ca. 40% vid dörrförsäljning!
        </li>
        <li>Ungdomarna får umgås tillsammans och röra på sig utomhus!</li>
        <li>
          Ungdomarna får ett diplom som kan anslås väl synligt i skolan, på
          idrottsklubben etc!
        </li>
        <li>
          Ungdomarna uppmärksammas på CleanApp&apos;s webbsida och sociala
          medier!
        </li>
        <li>
          Därtill slipper ni föräldrar, lagledare m.fl. all tidsödande
          administration, logistik och lagerhållning som det ofta innebär när
          hela klasser/lag ska sälja prylar, kakor eller godis för att tjäna
          extrapengar till klass- och lagkassan.
        </li>
      </ul>
      <p>
        Så se nu till att ungdomarna väljer ett eller flera områden område som
        passar dem och som behöver städas och GULMARKERA dessa på
        CleanApp-kartan. Ni bidrar sedan helt valfritt till deras städuppdrag!
      </p>
      <b>Företag/organisationer</b>
      <img
        alt="buske"
        src={require("../../../images/illustrations/bush.png")}
        style={{ float: "right", width: 200 }}
      />
      <p>
        Lokal reklam är effektiv reklam, det är ett känt begrepp inom
        marknadsföring sedan länge. Att synas och förknippas med positiva värden
        där dina kunder faktiskt finns är nästan alltid mer effektivt än
        storslagen och dyr reklam för anonyma massor.
      </p>
      <p>
        Genom att via CleanApp stötta en skola, en klass eller kanske ett
        idrottslag i ert lokalsamhälle skapar ditt företag en direkt nytta, och
        därtill en tacksamhet hos dina kunder, då området städas tack vare ditt
        stöd.
      </p>
      <p>
        Tack vara ditt viktiga stöd till ungdomarna och deras städuppdrag via
        CleanApp bidrar du på många sätt:
      </p>
      <ul>
        <li>Ungdomarna får möjlighet att tjäna extrapengar tillsammans!</li>
        <li>Ungdomarna städar i naturen istället för att kränga saker!</li>
        <li>Ungdomarna lära sig nyttan av att inte skräpa ner i vår natur!</li>
        <li>
          Ungdomarna får behålla 80% av pengarna till skillnad från ordinarie
          ca. 40% vid dörrförsäljning!
        </li>
        <li>Ungdomarna får umgås tillsammans och röra på sig utomhus!</li>
        <li>
          Ungdomarna får ett diplom som kan anslås väl synligt i skolan, på
          idrottsklubben etc!
        </li>
        <li>
          Ungdomarna uppmärksammas på CleanApp&apos;s webbsida och sociala
          medier!
        </li>
      </ul>
      <p>
        Som tack för ditt stöd får de företag/organisation som sponsrar
        ungdomars städuppdrag via CleanApp därför:
      </p>
      <ul>
        <li>Ett diplom som kan anslås väl synligt för era kunder!</li>
        <li>
          Ett digitalt sigill/emblem som kan anslås på er webbsida och i era
          sociala medier!
        </li>
        <li>
          Ni listas och får uppmärksamhet på CleanApp&apos;s webbsida och social
          medier!
        </li>
      </ul>
      <p>
        Så välj nu ett GULMARKERAT område som passar ert företag/organisation på
        CleanApp-kartan/listan och stötta ungdomarna via Swish eller faktura. Ni
        bidrar då med så mycket mer än bara sponsring av ett städuppdrag och vi
        vet att ditt företag kommer att få välförtjänt uppskattning för det!
      </p>
    </div>
  );
};

export default Sponsor;
