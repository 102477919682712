import React from "react";

const CleanApp = () => {
  return (
    <div>
      <b>CleanApp - lokala lösningar på ett globalt problem!</b>
      <p>
        Även om nedskräpningen till stor del är ett globalt problem så finns
        många av lösningarna på lokal nivå. Det är i våra lokalsamhällen och i
        vår vardag som vi tillsammans måste finna konstruktiva lösningar,
        stimulerande incitament och positiva synergieffekter som bidrar till att
        nedskräpningen minskar och att skräpet plockas upp.
      </p>
      <p>
        CleanApp är en av lösningarna som möjliggör allt detta och så mycket
        mer, så häng på!
      </p>
      <b>Så här fungerar CleanApp, steg för steg!</b>
      <img
        alt="buske"
        src={require("../../../images/illustrations/bush.png")}
        style={{ float: "right", width: 200 }}
      />
      <p>
        Det är egentligen väldigt enkelt, CleanApp är en digital plattform på
        vilken sponsorer, exempelvis lokala företagare, klassföräldrar,
        vägföreningar m.fl, matchas ihop med skolklasser, idrottslag och
        ungdomsföreningar som vill tjäna extrapengar. Tillsammans kommer
        sponsorerna och ungdomarna överens om ett geografiskt område som
        ungdomarna får betalt för att städa. Så enkelt, men ändå så värdefullt!
      </p>
      <p>
        Denna matchning är en win-win för alla inblandade och för naturen.
        Ungdomarna får komma ut i naturen, röra på sig och umgås, de gör en
        viktig samhällsinsats samtidigt som de utbildar sig om betydelsen av att
        inte skräpa ner. De tjänar därtill extrapengar och sist men inte minst -
        skräpet plockas upp och ert lokalsamhälle blir renare, tryggare och
        finare! Detta samtidigt som sponsorer får positiv reklam och välförtjänt
        uppmärksamhet för sin hjälp, helt enkelt en riktig win-win-lösning!
      </p>
      <p>
        Självklart kan initiativet till olika städuppdrag komma från både
        ungdomar eller sponsorer. Det viktiga är ju att skräpet plockas upp och
        att ungdomarna kommer ut och rör på sig, eller hur?
      </p>
      <p>Så här fungerar CleanApp, steg för steg:</p>
      <ul>
        <li>
          Om du och dina klass- eller lagkamrater vill tjäna extrapengar och få
          betalt för att städa ett eller flera naturområden markerar ni området
          på CleanApp-kartan och fyller i alla uppgifter - här finns det vilja
          att städa och vi söker sponsorer och stöttning!
        </li>
        <li>
          När du och dina klass- eller lagkamrater fyllt i alla era uppgifter
          kan ni förhandsgranska, redigera och därefter maila ut ett
          informationsmail om just ert valda städuppdrag. Detta informationsmail
          kan ni sedan sprida, printa och använda i ert arbeta med att få bidrag
          och sponsorer till ert städuppdrag, om ni då inte redan har det löst!
        </li>
        <img
          alt="löv"
          src={require("../../../images/illustrations/leaves.png")}
          style={{ float: "right", width: 300 }}
        />
        <li>
          Sedan är det bara att välja en dag eller ett tillfälle som passar er
          att utföra städuppdraget. Ni väljer helt själva men uppdraget ska vara
          utfört senast tre månader efter att ni accepterat uppdraget.
        </li>
        <li>
          Om du som sponsor/förälder finner ett redan GULMARKERAT område som
          passar dig så behöver du inte leta längre, grattis! Klicka bara på
          området och swisha ditt stöd eller ange att du vill stötta
          städuppdraget via faktura. Här kommer det inom kort vara städat och
          fint!
        </li>
        <li>
          Om ni inte själva redan har eller införskaffar egen städutrustning så
          erbjuder många kommuner idag kostnadsfria sopsäckar, skyddshandskar
          och även upphämtning av allt insamlat skräp för denna typ av
          frivilligstädningar. Kontakta just er kommun för att se om de
          eventuellt kan bidra med städutrustning, skräpupphämtning m.m. Bl.a.
          Göteborgs kommuns erbjudande kan ni ta del av här:{" "}
          <a
            href="//www.goteborg.se/wps/portal/start/gator-vagar-och-torg/stadning-av-staden/volontarstadning"
            rel="noreferrer"
            target="_blank"
          >
            www.goteborg.se/wps/portal/start/gator-vagar-och-torg/stadning-av-staden/volontarstadning
          </a>
        </li>
        <li>
          När ni är klara med städuppdraget bekräftar ni detta på CleanApp’s
          webbsida och kanske laddar upp ett par bilder, såsom exempelvis före-
          och efterbilder på det städade området, på alla soppåsar ni lyckades
          fylla med skräp eller kanske en bild på klassen/laget som städat
          tillsammans!
        </li>
        <li>
          Efter att städningen är utförd och bekräftad markeras området GRÖNT på
          CleanApp-kartan/listan under tre månader - här har det städats - och
          klassen/laget får betalt för sin viktiga insats! Ni får också ett
          diplom att anslå tydligt i skolan eller i klubbloken och laget/klassen
          listas på CleanApps webbsida och i våra sociala medier som en av alla
          de klasser/lag som gjort skillnad på riktigt - bra jobbat och ett
          stort tack för hjälpen!
        </li>
        <li>
          Varför inte nu välja fler områden eller kanske erbjuda städning på
          både vår och höst?
        </li>
      </ul>
      <b>CleanApp’s direkta och indirekta positiva effekter!</b>
      <img
        alt="buske"
        src={require("../../../images/illustrations/bush.png")}
        style={{ float: "right", width: 250 }}
      />
      <p>
        Den största och mest påtagliga nyttan med CleanApp är självklart att
        skräp i naturen plockas upp, men det är långt ifrån den enda!
      </p>
      <p>
        Till skillnad från exempelvis kak- och lotteriförsäljningen ger CleanApp
        flera andra direkta och indirekta positiva effekter varje gång ungdomar
        matchas ihop med en eller flera sponsorer och bidragsgivare så att det
        kan utföra ett städuppdrag via CleanApp:
      </p>
      <ul>
        <li>
          Ungdomarna får komma ut i naturen och umgås tillsammans. Studier visar
          på att både inne- och stillasittandet tyvärr ökar ibland dagens
          ungdomar. Detta riskerar bl.a. att leda till depression, rygg- och
          nackproblem och generellt sett sämre fysisk och psykisk hälsa med både
          kort- och långsiktiga problem till följd.
        </li>
        <li>
          Ungdomarna engageras att göra en viktig samhällsinsats för
          lokalsamhället genom att plocka upp skräp som annars förfular, skapar
          otrygghet och skadar vårt djur- och naturliv. Studier visar även på
          att ungdomar som på ett eller annat sätt deltar i renhållningsprojekt
          skräpar ner mindre själva hädanefter.
        </li>
        <li>
          Ungdomarna får tillsammans arbete för att tjäna ihop pengar till
          klass- eller lagkassan. Med CleanApp får de behålla 80% av pengarna,
          till skillnad från vanligtvis ca 40% vid dörrförsäljning m.m.
        </li>
        <li>
          Föräldrar, lagledare m.fl. slipper all tidsödande administration,
          logistik, lagerhållning m.m. som det ofta innebär när hela klasser/lag
          ska sälja prylar, kakor eller godis för att tjäna extrapengar till
          klass- och lagkassan.
        </li>
        <li>
          Kort och gott; är det inte även roligare att tillsammans städa er fina
          park, favoritbadstrand eller i och omkring pulkabacken i skogen än att
          på tu man hand knacka dörr för att kränga prylar och sötsaker?
        </li>
      </ul>
    </div>
  );
};

export default CleanApp;
