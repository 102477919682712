import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";

import styles from "./segment.module.css";

const Segment = ({ className, label, onChange, options, value }) => {
  return (
    <div className={className}>
      {!!label && <div className={styles.label}>{label}</div>}
      <div className={styles.segment}>
        {options.map((item, index) => (
          <React.Fragment key={index}>
            {index > 0 && <div className={styles.separator} />}
            <div
              className={classNames({
                [styles.item]: true,
                [styles.selected]: index === value,
              })}
              onClick={() => onChange(index)}
            >
              {item}
            </div>
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

Segment.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.string),
  value: PropTypes.number,
};

Segment.defaultProps = {
  className: null,
  label: "",
  onChange: null,
  options: "",
  value: 0,
};

export default Segment;
