import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { NotificationContainer } from "react-notifications";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import { green, yellow } from "../../constants/colors";
import { get } from "../../util/server";
import About from "../About/About";
import App from "../App/App";
import LandingPage from "../LandingPage/LandingPage";
import Menu from "../Menu/Menu";
import PrintTask from "../PrintTask/PrintTask";
import styles from "./root.module.css";

// Attach the modal to the root element.
Modal.setAppElement("#root");

const Root = () => {
  const [tasks, setTasks] = useState([]);

  useEffect(() => {
    const fetchTasks = async () => {
      const response = await get("tasks");
      if (response.success) {
        const tasks = response.tasks.map((item) => {
          return {
            cleaned: item.cleaned,
            cleaner: item.cleaner,
            color: item.cleaned ? green : yellow,
            contact: item.contact,
            created: item.created,
            currentAmount: item.currentAmount,
            deadline: item.deadline,
            description: item.description,
            id: item.taskId,
            lat: item.latitude,
            lng: item.longitude,
            name: item.name,
            purpose: item.purpose,
            totalAmount: item.totalAmount,
          };
        });
        setTasks(tasks);
      }
    };
    fetchTasks();
  }, []);

  return (
    <BrowserRouter>
      <Switch>
        <Route
          path="/info"
          render={(routeProps) => <LandingPage {...routeProps} />}
        />
        <Route
          path="/uppdrag/:id/print"
          render={(routeProps) => <PrintTask tasks={tasks} {...routeProps} />}
        />
        <Route>
          <div className={styles.container}>
            <Menu />
            <div className={styles.content} id="root-content">
              <Switch>
                <Route component={About} path={["/om/:section?"]} />
                <Route
                  path={["/uppdrag/skapa", "/uppdrag/:id?/:hash?", "/"]}
                  render={(routeProps) => <App tasks={tasks} {...routeProps} />}
                />
              </Switch>
            </div>
          </div>
          <NotificationContainer />
        </Route>
      </Switch>
    </BrowserRouter>
  );
};

export default Root;
