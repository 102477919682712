import React from "react";
import { Link } from "react-router-dom";

import styles from "./logo.module.css";

const Logo = ({ width }) => {
  return (
    <Link className={styles.container} to="/">
      <img
        alt="logo"
        src={require("../../../images/logo.png")}
        style={{ height: width * 0.4, width }}
      />
    </Link>
  );
};

export default Logo;
