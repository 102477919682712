import React from "react";

const InfoLeader = () => {
  return (
    <div>
      <b>Gör nytta och tjäna extrapengar!</b>
      <img
        alt="barn"
        src={require("../../../images/illustrations/child-money.png")}
        style={{ float: "right", width: 300 }}
      />
      <p>
        Planerar ni en klassresa, kanske ett träningsläger eller ny utrustning
        till en träningshall? Oavsett varför så finns det mängder av skäl för
        exempelvis en skolklass eller ett idrottslag att tillsammans vilja tjäna
        extrapengar och det vill vi så klart hjälpa er med!
      </p>
      <p>
        Till skillnad från att knacka dörr för att sälja tubsockor, godis och
        kakor, och då vanligtvis få behålla cirka 40% av pengarna ungdomarna
        slitit för, kan ni med CleanApp istället tjäna ihop extrapengar genom
        att tillsammans umgås och röra på er utomhus, göra en viktig
        samhällsinsats för lokalsamhället genom att hjälpa till att hålla rent
        och därtill behålla 80% av pengarna - svårslaget, eller hur?!
      </p>
      <p>
        Ni i klassen/laget får inte bara betalt för er viktiga insats. Ni får
        även ett diplom att anslå tydligt i skolan eller i klubbloken, ni listas
        på CleanApps webbsida och får uppmärksamhet i våra sociala medier som en
        av alla klasser/lag som gjort skillnad på riktigt - bra jobbat och ett
        stort tack för hjälpen!
      </p>
      <p>
        Därtill slipper era föräldrar, lagledare m.fl. all tidsödande
        administration, logistik och lagerhållning som det ofta innebär när hela
        klasser/lag ska sälja prylar, kakor eller godis för att tjäna
        extrapengar till klass- och lagkassan.
      </p>
      <p>
        Enligt svenska skolans kurs- och läroplaner ska barn och ungdomar ges
        möjlighet att utveckla kunskaper om hur de olika val som människor gör
        kan bidra till en hållbar utveckling – såväl ekonomisk och social som
        miljömässig.
      </p>
      <p>
        Att därtill främja fysisk aktivitet, och då gärna utomhus, är en viktig
        del av att bibehålla och utveckla barn och ungdomars förmåga till
        välbefinnande, livslångt lärande och personliga utveckling.
      </p>
      <p>
        Genom att engagera en skolklass eller ett lag i ett städuppdrag via
        CleanApp kan flera olika nyttor uppnås och därför integreras i er
        ordinarie utbildnings- eller träningsverksamhet, exempelvis:
      </p>
      <img
        alt="löv"
        src={require("../../../images/illustrations/leaf.png")}
        style={{ float: "right", width: 200 }}
      />
      <ul>
        <li>
          Använd en lektion/samling till att gå igenom nedskräpningens skador på
          djur- och naturlivet. På bl.a. dessa webbsidor kan ni finna mycket bra
          utbildningsmateriel rörande bl.a. ekologi, nerskräpning och betydelsen
          av renhållning av vår natur:
          <ul>
            <li>
              <a
                href="//www.wwf.se/utbildning"
                rel="noreferrer"
                target="_blank"
              >
                www.wwf.se/utbildning/
              </a>
            </li>
            <li>
              <a
                href="//www.sopor.nu/minska-ditt-avfall/skola"
                rel="noreferrer"
                target="_blank"
              >
                www.sopor.nu/minska-ditt-avfall/skola/
              </a>
            </li>
          </ul>
        </li>
        <li>
          Låt klassens/lagets insamling av bidrag och sponsorer bli en aktivitet
          att samlas kring och för att sporra varandra.
        </li>
        <li>
          Låt genomförande av ert städuppdrag bli en härlig dag tillsammans
          utomhus och en möjlighet att skapa sammanhållning för att stärka den
          viktiga lag- och klasskänslan!
        </li>
        <li>
          Efter det genomförda städuppdraget kan ni använda en lektion/samling
          till att utvärdera er insats och gå igenom vad ni hittade i naturen.
          Vad var mest överraskade att finna, vad hade varit mest skadligt om ni
          inte varit där och plockat upp det och sist men inte minst; vad ska ni
          nu använda era välförtjänta extrapengar till?
        </li>
        <li>
          Glöm inte att alltid vara försiktiga när ni utför ert städuppdrag!
          Håll alltid tillräckligt avstånd till trafik, vägar, branter, vatten
          m.m. Tänk även på att skydda händerna med handskar mot eventuella
          glasskärvor m.m. CleanApp kan tyvärr inte ansvara för eventuella
          skador eller olyckor om dessa skulle ske i samband med utförda
          städuppdrag och tillhandahåller inte någon speciell
          olycksfallsförsäkring etc.
        </li>
      </ul>
      <b>Personuppgifter och övriga villkor</b>
      <p>
        Genom att starta och genomföra ett städuppdrag via CleanApp By Allwin
        godkänner angiven kontaktperson och samtliga deltagare att CleanApp By
        Allwin sparar inlämnade person- och kontaktuppgifter, och vid behov även
        sprider dessa uppgifter till tredje part, för att kunna utföra och
        utveckla CleanApps tjänster. Därtill godkänns samtliga CleanApp By
        Allwins, på denna hemsida, angivna tjänste-, debiterings- och
        kommunikationslösningar i sin helhet och utan förbehåll.
      </p>
    </div>
  );
};

export default InfoLeader;
