import styles from "./links.module.css";

export default function Links() {
  return (
    <div className={styles.container}>
      <div className={styles.item}>www.cleanapp.se</div>
      <div className={styles.separator}>/</div>
      <div className={styles.item}>www.facebook.com/CleanApp.se</div>
      <div className={styles.separator}>/</div>
      <div className={styles.item}>info@cleanapp.se</div>
    </div>
  );
}
