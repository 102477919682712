import React from "react";
import { NavLink } from "react-router-dom";

import styles from "./listItem.module.css";

const ListItem = ({ cleaner, color, id, name }) => {
  return (
    <NavLink
      activeClassName={styles.active}
      className={styles.container}
      to={(location) => {
        const link = `/uppdrag/${id}`;
        const url = location?.pathname === link ? "/uppdrag/" : link;
        return url;
      }}
    >
      <div className={styles.indicator} style={{ backgroundColor: color }} />
      <div>
        {cleaner} har startat städuppdraget {name}.
      </div>
    </NavLink>
  );
};

export default ListItem;
