import React from "react";

import styles from "./detailItem.module.css";

const DetailItem = ({ label, value }) => {
  return (
    <div className={styles.container}>
      <div className={styles.label}>{label}</div>
      <div>{value}</div>
    </div>
  );
};

export default DetailItem;
