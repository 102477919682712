import React from "react";

import { formatAmount } from "../../../../../../util/string";
import { Button } from "../../../../../shared";
import styles from "./amountButtons.module.css";

const AmountButtons = ({ onChange, selectedAmount }) => {
  const items = [50, 150, 500];
  return (
    <div className={styles.container}>
      {items.map((item) => (
        <Button
          className={styles.item}
          color={selectedAmount === item ? "green" : "lightGrey"}
          key={item}
          onClick={() => onChange(item)}
          title={formatAmount(item)}
        />
      ))}
    </div>
  );
};

export default AmountButtons;
