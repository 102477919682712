import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";

import { post } from "../../../util/server";
import { Button, TextInput } from "../../shared";
import styles from "./title.module.css";

const Title = ({ onSwishClick, value }) => {
  const params = useParams();
  const [isEditing, setIsEditing] = useState(false);
  const [newValue, setNewValue] = useState(value);

  const canEdit = !!params?.hash;
  const onToggle = () => setIsEditing(!isEditing);
  const onSubmit = async () => {
    post("update_task", {
      hash: params?.hash,
      taskId: params?.id,
      title: newValue,
    });
    setIsEditing(false);
  };

  return (
    <div>
      {isEditing ? (
        <TextInput
          inputClassName={styles.input}
          onChange={setNewValue}
          value={newValue}
        />
      ) : (
        <div className={styles.titleContainer}>
          <Link to="/uppdrag">
            <img
              alt="tillbaka"
              className={styles.backIcon}
              src={require("../../../images/icons/back.png")}
            />
          </Link>
          <div className={styles.title}>{newValue || value}</div>
          <div className={styles.swishButton} onClick={onSwishClick}>
            <img
              alt="Swish"
              src={require("../../../images/partners/swish-horizontal.png")}
            />
          </div>
        </div>
      )}
      {canEdit && (
        <div className={styles.toolbar}>
          {isEditing && (
            <Button
              className={styles.submitButton}
              color="green"
              onClick={onSubmit}
              title="Uppdatera"
            />
          )}
          <Button
            onClick={onToggle}
            title={isEditing ? "Avbryt" : "Ändra titel?"}
          />
        </div>
      )}
    </div>
  );
};

export default Title;
