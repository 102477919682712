import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { showError, showSuccess } from "../../../util/message";
import { post } from "../../../util/server";
import { Button } from "../../shared";
import styles from "./confirmButton.module.css";

const ConfirmButton = ({ cleaned }) => {
  const [submitting, setSubmitting] = useState(false);
  const params = useParams();

  useEffect(() => {
    return () => {
      return () => {
        clearTimeout(timeout);
      };
    };
  });

  if (!params?.hash) {
    return null;
  }

  let timeout = null;

  const onSubmit = async () => {
    setSubmitting(true);
    const confirm = window.confirm("Säker du vill slutföra uppdraget?");
    if (confirm) {
      const response = await post("confirm_task", {
        hash: params?.hash,
        revert: cleaned,
        taskId: params?.id,
      });
      if (response.success) {
        showSuccess(
          cleaned ? "Uppdraget är återupptaget" : "Uppdraget är slutfört!"
        );
        timeout = setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        showError(
          cleaned
            ? "Kunde inte återuppta uppdraget"
            : "Kunde inte slutföra uppdraget"
        );
        setSubmitting(false);
      }
    } else {
      setSubmitting(false);
    }
  };

  return (
    <Button
      className={styles.submit}
      color={cleaned ? "red" : "green"}
      disabled={submitting}
      onClick={onSubmit}
      title={cleaned ? "Återuppta uppdraget" : "Bekräfta uppdrag slutfört"}
    />
  );
};

export default ConfirmButton;
