import React from "react";

const FAQ = () => {
  return (
    <div>
      <b>Vilka är det största nyttorna med CleanApp?</b>
      <p>
        Den största och mest påtagliga nyttan med CleanApp är självklart att
        skräp i naturen plockas upp, men det är långt ifrån den enda!
      </p>
      <p>
        Till skillnad från exempelvis kak- och lotteriförsäljningen ger CleanApp
        flera andra direkta och indirekta positiva effekter varje gång ungdomar
        matchas ihop med en eller flera sponsorer och bidragsgivare så att det
        kan utföra ett städuppdrag via CleanApp.
      </p>
      <p>
        Ungdomarna får komma ut i naturen och umgås tillsammans. Studier visar
        på att både inne- och stillasittandet tyvärr ökar ibland dagens
        ungdomar. Detta riskerar bl.a. att leda till depression, rygg- och
        nackproblem och generellt sett sämre fysisk och psykisk hälsa med både
        kort- och långsiktiga problem till följd.
      </p>
      <p>
        Ungdomarna engageras att göra en viktig samhällsinsats för
        lokalsamhället genom att plocka upp skräp som annars förfular, skapar
        otrygghet och skadar vårt djur- och naturliv. Studier visar även på att
        ungdomar som på ett eller annat sätt deltar i renhållningsprojekt
        skräpar ner mindre själva hädanefter.
      </p>
      <p>
        Ungdomarna får tillsammans arbeta för att tjäna ihop pengar till klass-
        eller lagkassan. Med CleanApp får de behålla 80% av pengarna, till
        skillnad från vanligtvis ca 40% vid dörrförsäljning m.m.
      </p>
      <p>
        Föräldrar, lagledare m.fl. slipper all tidsödande administration,
        logistik, lagerhållning m.m. som det ofta innebär när hela klasser/lag
        ska sälja prylar, kakor eller godis för att tjäna extrapengar till
        klass- och lagkassan.
      </p>
      <p>
        Kort och gott; är det inte även roligare att tillsammans städa er fina
        park, favoritbadstrand eller i och omkring pulkabacken i skogen än att
        på tu man hand knacka dörr för att kränga prylar och sötsaker?
      </p>
      <b>Vem tillhandahåller städutrustning och hämtar uppsamlat skräp?</b>
      <p>
        Om ni inte själva införskaffar egen städutrustning så erbjuder många
        kommuner idag kostnadsfria sopsäckar, skyddshandskar och även
        upphämtning av allt insamlat skräp för denna typ av frivilligstädningar.
        Kontakta just er kommun för att se om de eventuellt kan bidra med
        städutrustning, skräpupphämtning m.m. Bl.a. Göteborgs kommuns erbjudande
        kan ni ta del av här:{" "}
        <a
          href="//www.goteborg.se/wps/portal/start/gator-vagar-och-torg/stadning-av-staden/volontarstadning"
          rel="noreferrer"
          target="_blank"
        >
          www.goteborg.se/wps/portal/start/gator-vagar-och-torg/stadning-av-staden/volontarstadning
        </a>
      </p>
      <b>Hur mycket pengar kan man som klass/lag samla in?</b>
      <p>
        Det är upp till ungdomarna att välja ett eller flera områden som enskilt
        motsvarar det upplevda värdet så att företag, föräldrar m.fl. vill
        sponsra och bidra till utvalda städuppdrag med sammantaget det målbelopp
        ni väljer.
      </p>
      <b>När och hur utbetalas insamlade pengar till klassen/laget?</b>
      <p>
        Efter att ert städuppdrag är utfört och bekräftat får klassen/laget
        betalt för sin viktiga insats. Utbetalningen av insamlade pengar sker
        genom Swish till angivet mobilnummer till ansvarig kontaktperson.
      </p>
      <b>Tar CleanApp någon avgift för sina tjänster?</b>
      <p>
        CleanApp tar enbart ut en deltagar- och administrationsavgift, på 20% av
        insamlade pengar. Det tillkommer inga övriga fasta eller rörliga
        kostnader eller avdrag för CleanApp´s administration, trycksaker,
        kommunikation, betalningstjänster m.m. Då deltagaravgiften är fast på
        20% så innebär det att klassen/laget alltid får behålla 80% av varje
        insamlad krona som går direkt till ungdomarnas städuppdrag och
        klass-eller lagkassa!
      </p>
    </div>
  );
};

export default FAQ;
