import styles from "./landingPage.module.css";

export default function LandingPage() {
  return (
    <div className={styles.outerContainer}>
      <div className={styles.innerContainer}>
        <div className={styles.hero}>
          Tjäna pengar till klassen, laget eller föreningen genom att städa i
          naturen!
        </div>
        <img
          alt="CleanApp"
          className={styles.logo}
          src={require("../../images/logo.png")}
        />
        <div className={styles.content}>
          <p>
            Tröttnat på att knacka dörr för sälja prylar, kakor eller lotter?
            Tröttnat på att bara få behålla 40% av pengarna?
          </p>
          <p>
            Städa istället naturen med CleanApp, umgås och ha kul utomhus, gör
            en viktig insats för naturen och lokalsamhället och behåll 80% av
            pengarna!
          </p>
          <div>
            <p>Så enkelt är det, steg-för-steg:</p>
            <div className={styles.listContainer}>
              <ol className={styles.list}>
                <li>
                  Välj en plats i ert närområde - kanske en park, strand eller
                  skogsdunge - som ni alla tycker behöver städas, ange hur
                  mycket pengar ni vill samla in genom att städa här, utse en
                  kontaktperson och registrera ert städuppdrag på{" "}
                  <a href="https://www.cleanapp.se" rel="noreferrer">
                    www.cleanapp.se
                  </a>
                  ! Inga förköp, inga fasta kostnader eller avgifter och inga
                  bindningstider!
                </li>
                <li>
                  Låt sedan alla föräldrar, grannar och bekanta enkelt bidra via
                  Swish till ert städuppdrag och ert engagemang för en renare
                  natur! På{" "}
                  <a href="https://www.cleanapp.se" rel="noreferrer">
                    www.cleanapp.se
                  </a>{" "}
                  får varje städuppdrag ett eget informationsmaterial som både
                  kan printas eller mailas för att spridas till dem alla och
                  underlätta er insamling!
                </li>
                <li>
                  När ni samlat ihop ert valfria målbelopp så genomför ni
                  tillsammans städuppdraget, anmäler det sedan som utfört och
                  får pengarna utbetalda!
                </li>
                <li>
                  Så enkelt är det, läs mer här och starta ert städuppdrag!
                </li>
              </ol>
              <img
                alt="Barn leker"
                className={styles.listImage}
                src={require("../../images/kids/kid.jpg")}
              />
              <img
                alt="Barn leker"
                className={styles.listImageSquare}
                src={require("../../images/kids/kid-square.jpg")}
              />
            </div>
          </div>
          <a
            className={styles.link}
            href="https://www.cleanapp.se"
            rel="noreferrer"
          >
            Starta här!
          </a>
          <p className={styles.about}>
            2006 grundades Stiftelsen för Gemensamt Engagemang med ändamål att
            bl.a. främja goda miljöer och verksamhet som leder till ökad
            självkänsla samt ökat självförtroende hos ungdomar,
            långtidsarbetslösa och utstötta i samhället. 2010 grundades Allwin
            AB, som sedan starten och än idag ägs av Stiftelsen för Gemensamt
            Engagemang, för att förebygga och minska matsvinnet och för att
            hjälpa utsatta att få tillgång till näringsrik mat i Sverige.
            Parallellt med Allwins viktiga och framgångsrika arbete beslutade
            Stiftelsen för Gemensamt Engagemang våren 2020 att, utöver
            matsvinnet, nu angripa ytterligare ett stort samhällsproblem -
            nedskräpningen i vår natur - och detta blev plattformen CleanApp!
          </p>
        </div>
      </div>
    </div>
  );
}
