import React from "react";

import { useWindowSize } from "../../../hooks";
import { Partners } from "../../shared";
import Links from "../Links/Links";
import Logo from "../Logo/Logo";
import styles from "./desktopMenu.module.css";

const DesktopMenu = ({ items, slogan }) => {
  const windowSize = useWindowSize();
  const partnerSize = windowSize?.width < 1350 ? 16 : 24;
  return (
    <div className={styles.container}>
      <Logo width={120} />
      <div className={styles.slogan}>{slogan}</div>
      <Links direction="row" items={items} />
      <Partners size={partnerSize} />
    </div>
  );
};

export default DesktopMenu;
