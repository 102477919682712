import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import QRCode from "react-qr-code";

import { get, post } from "../../../../../util/server";
import { formatAmount } from "../../../../../util/string";
import { Animation } from "../../../../shared";
import AmountButtons from "./AmountButtons/AmountButtons";
import styles from "./swishOverlay.module.css";

const SwishOverlay = ({ onClose, taskId }) => {
  const [amount, setAmount] = useState(150);
  const [swishToken, setSwishToken] = useState(null);
  const [hasPaid, setHasPaid] = useState(false);

  useEffect(() => {
    const fetchSwishToken = async () => {
      const response = await post("create_payment", { amount, taskId });
      if (response.success) {
        setSwishToken(response.paymentToken);
      }
    };
    fetchSwishToken();
  }, [amount, taskId]);

  useEffect(() => {
    let timeout;
    const fetchPaymentStatus = async () => {
      const response = await get("payment", { paymentToken: swishToken });
      if (response.success && response.isPaid) {
        setHasPaid(true);
        timeout = setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        timeout = setTimeout(fetchPaymentStatus, 2000);
      }
    };
    if (taskId && swishToken) {
      fetchPaymentStatus();
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [swishToken, taskId]);

  return (
    <Modal
      className={styles.modal}
      isOpen={!!taskId}
      onRequestClose={onClose}
      overlayClassName={styles.overlay}
    >
      <img
        alt="stäng"
        className={styles.closeIcon}
        onClick={onClose}
        src={require("../../../../../images/icons/close.png")}
      />
      <AmountButtons onChange={setAmount} selectedAmount={amount} />
      <div className={styles.info}>
        <Animation key={hasPaid}>
          {hasPaid
            ? "Tack för din betalning!"
            : `Skanna QR-koden med Swish-appen eller klicka på länken nedan för att stötta detta uppdrag med ${formatAmount(
                amount
              )}.`}
        </Animation>
      </div>
      <a
        className={styles.link}
        href={`swish://paymentrequest?token=${swishToken}`}
      >
        Öppna Swish
      </a>
      <QRCode value={`D${swishToken}`} />
    </Modal>
  );
};

export default SwishOverlay;
