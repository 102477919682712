export const getContrastColor = (color, dark = "#000", light = "#fff") => {
  if (!color) {
    return null;
  }
  const { b, g, r } = hexToRgb(color);
  if (0.2126 * r + 0.7152 * g + 0.0722 * b > 179) {
    return dark;
  } else {
    return light;
  }
};

const hexToRgb = (hex) => {
  const color = formatColor(hex);
  if (color) {
    const result = /^([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(color);
    return result
      ? {
          b: parseInt(result[3], 16),
          g: parseInt(result[2], 16),
          r: parseInt(result[1], 16),
        }
      : null;
  }
};

const formatColor = (hex) => {
  hex = hex.replace(/^#/, "");
  if (hex.length === 3) {
    return hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  } else if (hex.length === 6) {
    return hex;
  }
  return null;
};
