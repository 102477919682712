import React from "react";
import { Link } from "react-router-dom";

import styles from "./printButton.module.css";

export default function PrintButton() {
  return (
    <Link
      className={styles.container}
      target="_blank"
      to={(location) => `${location.pathname}/print`}
    >
      Skriv ut
    </Link>
  );
}
