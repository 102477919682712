import React from "react";

import styles from "./partners.module.css";

const Partners = () => {
  return (
    <div>
      <b>Almi - vår investerare</b>
      <div>
        <img
          alt="almi logga"
          className={styles.logo}
          src={require("../../../images/partners/almi.jpeg")}
        />
      </div>
      <b>SEB - vår bank</b>
      <div>
        <img
          alt="seb logga"
          className={styles.logo}
          src={require("../../../images/partners/seb.jpeg")}
        />
      </div>
      <b>Swish - vår betalningsleverantör</b>
      <div>
        <img
          alt="swish logga"
          className={styles.logo}
          src={require("../../../images/partners/swish-horizontal.png")}
        />
      </div>
    </div>
  );
};

export default Partners;
