import React from "react";
import { NavLink } from "react-router-dom";

import styles from "./tableOfContents.module.css";

const TableOfContents = ({ items }) => {
  return (
    <div className={styles.container}>
      {items.map((item, index) => (
        <NavLink className={styles.item} key={item.url} to={`/om/${item.url}`}>
          <div className={styles.title}>
            {index + 1}. {item.title}
          </div>
          <div className={styles.description}>{item.description}</div>
          <div className={styles.readMore}>Läs mer</div>
        </NavLink>
      ))}
    </div>
  );
};

export default TableOfContents;
