import axios from "axios";

import { apiUrl } from "../constants/config";
import { showError } from "./message";

export const post = async (endpoint, params = {}) => {
  try {
    const url = `${apiUrl}/post/${endpoint}.php`;
    const response = await axios.get(url, { params });
    return response.data;
  } catch (err) {
    showError();
    return {};
  }
};

export const get = async (endpoint, params = {}) => {
  try {
    const url = `${apiUrl}/get/${endpoint}.php`;
    const response = await axios.get(url, { params });
    return response.data;
  } catch (err) {
    showError();
    return {};
  }
};
