import React from "react";

import styles from "./toggleButton.module.css";

const ToggleButton = ({ checked, color, label, onToggle }) => {
  const onClick = () => {
    onToggle(!checked);
  };

  return (
    <div className={styles.container} onClick={onClick}>
      <div
        className={styles.checkbox}
        dangerouslySetInnerHTML={{ __html: checked ? "&check;" : "" }}
        style={{ backgroundColor: color }}
      />
      {label}
    </div>
  );
};

export default ToggleButton;
