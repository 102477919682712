import React, { useState } from "react";
import { Link } from "react-router-dom";

import { useWindowSize } from "../../hooks";
import { showError } from "../../util/message";
import { post } from "../../util/server";
import { Animation, Button, Checkbox, TextInput } from "../shared";
import styles from "./newTask.module.css";

const NewTask = ({ marker, onClose, onSuccess }) => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [cleaner, setCleaner] = useState("");
  const [purpose, setPurpose] = useState("");
  const [deadline, setDeadline] = useState("");
  const [amount, setAmount] = useState("");
  const [contact, setContact] = useState("");
  const [mail, setMail] = useState("");
  const [swish, setSwish] = useState("");
  const [terms, setTerms] = useState(false);
  const windowSize = useWindowSize();

  const isValid = () => {
    if (terms && name && contact && cleaner && swish && mail) {
      return true;
    }
    return false;
  };

  const onSubmit = async () => {
    if (!isValid()) {
      showError("Vänligen fyll i alla obligatoriska fält.");
      return false;
    }

    const params = {
      amount: amount,
      cleaner: cleaner,
      contact: contact,
      deadline: deadline,
      description: description,
      latitude: marker.lat,
      longitude: marker.lng,
      mail: mail,
      name: name,
      purpose: purpose,
      swish: swish,
    };
    const response = await post("create_task", params);
    if (response.success) {
      onSuccess(response.taskId);
    }
  };

  return (
    <div className={styles.outerContainer}>
      <div className={styles.innerContainer}>
        <div
          className={styles.content}
          style={{ maxHeight: windowSize?.height / 2 - 64 || "none" }}
        >
          <Animation>
            <div className={styles.title}>1. Var behövs det städas?</div>
            <div className={styles.markerInfo}>
              {marker
                ? "Klicka på kartan igen för att flytta markören."
                : "Zooma in och klicka på kartan för att placera en markör."}
            </div>
          </Animation>
          {!!marker && (
            <Animation>
              <div className={styles.title}>2. Detaljer om uppdraget</div>
              <TextInput
                className={styles.item}
                label="* Namn på vårt städuppdraget:"
                onChange={setName}
                placeholder="Städning av Storskogen"
                value={name}
              />
              <TextInput
                className={styles.item}
                label="Beskrivning av städuppdraget:"
                multiline
                onChange={setDescription}
                placeholder="Vi ska under en heldag städa i och omkring Storskogen"
                value={description}
              />
              <TextInput
                className={styles.item}
                label="* Vi som antar städuppdraget och samlar in extrapengar:"
                onChange={setCleaner}
                placeholder="klass 6b på Storskolan"
                value={cleaner}
              />
              <TextInput
                className={styles.item}
                label="Vi vill använda insamlade extrapengar till:"
                onChange={setPurpose}
                placeholder="träningsutrustning, klassresa etc"
                value={purpose}
              />
              <TextInput
                className={styles.item}
                label="Städuppdraget ska senast vara utfört:"
                onChange={setDeadline}
                placeholder="YYYY-MM-DD"
                value={deadline}
              />
              <div className={styles.title}>3. Målbelopp</div>
              <TextInput
                className={styles.item}
                label={
                  <div>
                    Ni väljer själva målbelopp, dvs hur mycket pengar ni siktar
                    på att samla in (
                    <Link target="_blank" to="/om/pengar-till-ungdomarna">
                      läs mer här
                    </Link>
                    ):
                  </div>
                }
                onChange={setAmount}
                placeholder="5000"
                type="number"
                value={amount}
              />
              <div className={styles.title}>4. Kontaktperson</div>
              <TextInput
                className={styles.item}
                label="* Namn på kontaktperson:"
                onChange={setContact}
                value={contact}
              />
              <TextInput
                className={styles.item}
                label="* E-post till kontaktperson:"
                onChange={setMail}
                value={mail}
              />
              <TextInput
                className={styles.item}
                label="* Mobilnummer till kontaktperson (används bl.a. för swishutbetalning av insamlade pengar):"
                onChange={setSwish}
                value={swish}
              />
              <Checkbox
                className={styles.terms}
                label={
                  <div>
                    Jag har tagit del av CleanApp’s information och villkor (
                    <Link target="_blank" to="/om">
                      läs mer
                    </Link>
                    )
                  </div>
                }
                onChange={setTerms}
                value={terms}
              />
            </Animation>
          )}
          <div className={styles.bottomFade} />
        </div>
      </div>
      <div className={styles.toolbar}>
        <Button
          className={styles.button}
          color="green"
          onClick={onSubmit}
          title="Spara uppdrag"
        />
        <Button
          className={styles.button}
          color="red"
          onClick={onClose}
          title="Avbryt"
        />
      </div>
    </div>
  );
};

export default NewTask;
