import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";

import { darkGrey, green, lightGrey, red } from "../../../constants/colors";
import { getContrastColor } from "../../../util/color";
import styles from "./button.module.css";

const Button = ({ className, color, disabled, onClick, title }) => {
  const availableColors = { green: green, lightGrey: lightGrey, red: red };
  const backgroundColor = availableColors[color];
  const fontColor = getContrastColor(backgroundColor, darkGrey, "#fff");
  const opacity = disabled ? 0.3 : 1;

  return (
    <div
      className={classNames({ [styles.container]: true, [className]: true })}
      onClick={disabled ? null : onClick}
      style={{ backgroundColor, color: fontColor, opacity }}
    >
      {title}
    </div>
  );
};

Button.propTypes = {
  className: PropTypes.string,
  color: PropTypes.oneOf(["green", "lightGrey", "red"]),
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  title: PropTypes.string,
};

Button.defaultProps = {
  className: null,
  color: "lightGrey",
  disabled: false,
  onClick: null,
  title: "",
};

export default Button;
