import Animate from "@charlesvien/react-animatecss";
import PropTypes from "prop-types";
import React from "react";

const Animation = ({ children, visible }) => {
  return (
    <Animate
      animationIn="fadeIn"
      animationOut="fadeOut"
      inDuration={1000}
      outDuration={500}
      repeat={1}
      visible={visible}
    >
      {children}
    </Animate>
  );
};

Animation.propTypes = {
  children: PropTypes.any,
  visible: PropTypes.bool,
};

Animation.defaultProps = {
  children: null,
  visible: true,
};

export default Animation;
