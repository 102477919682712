export const capitalize = (str) => {
  if (str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  return "";
};

export const uncapitalize = (str) => {
  if (str) {
    return str.charAt(0).toLowerCase() + str.slice(1);
  }
  return "";
};

export const formatAmount = (amount, decimals = 0) => {
  const parsedNumber = parseFloat(amount)?.toFixed(decimals);
  const formatNumber = parsedNumber?.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  return `${formatNumber} kr`;
};
